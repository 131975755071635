import { Container, RichText } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import React, { ReactElement } from 'react';
import { Props } from './types';

const RichTextTableTemplate = ({
  table,
  topMargin,
  bottomMargin,
}: Props): ReactElement => {
  const [pt, pb] = useContainerPadding({
    top: topMargin,
    bottom: bottomMargin,
  });

  return (
    <Container containerSx={{ pt, pb }}>
      <RichText richTextObject={table} />
    </Container>
  );
};

export default RichTextTableTemplate;
